<template>
  <div class="darg-to-dress-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNO">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      />
      <!--  :isRowMenu="true" -->
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">{{ titleInfo.hanzi }}</div>
        </div>
      </div>

      <div class="background-area">
        <img :src="bgImgArr[currentIndex].bgImg" class="bg-img" />
        <template v-for="(item, index) in bgImgList[currentIndex]">
          <img
            :src="item.bgImg"
            class="bg-img-item"
            :class="{ jacketIndex: item.index === 6 }"
            :key="index + 'bg'"
            v-show="dispearIdList.indexOf(item.id) !== -1"
          />
        </template>
      </div>

      <div
        class="drop-area"
        :class="'dropBox-' + item.index"
        v-for="(item, index) in bgImgList[currentIndex]"
        :key="index + 'drop'"
      >
        <div
          :class="{ dispear: dispearIdList.indexOf(dragIndex) === -1 }"
          @drop.stop="handleDropImg($event, item)"
          @dragover.stop="allowDrop($event)"
          draggable="true"
        />
      </div>

      <div class="drag-area">
        <div
          v-for="(item, index) in optionImgList[currentIndex]"
          class="option-item"
          :class="'option-' + item.index"
          :key="index + 'drag'"
          v-show="dispearIdList.indexOf(item.id) === -1"
        >
          <img
            :class="{ notShow: dragIndex === item.index }"
            :src="item.image"
            draggable="true"
            @dragstart.stop="handleDragImg($event, item)"
            @drop.stop="Sdrop($event)"
            @dragend.stop="dragend()"
          />
        </div>
      </div>
    </div>
    <div class="hanzi-item" v-if="isShowSentence && hasSentence">
      <div class="pinyin sentence-hanzi font-pinyin-medium">
        {{ bgImgArr[currentIndex].pinyin }}
      </div>
      <div class="sentence-hanzi font-hanzi-medium">
        {{ bgImgArr[currentIndex].hanzi }}
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
export default {
  name: "DragToGroupDressGame",
  props: {
    optionImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgArr: {
      type: Array,
      require: true,
    },
    lessonNO: {
      type: Number,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: false,
      default: () => {},
    },
    hasStar: {
      type: Boolean,
      require: false,
      default: true,
    },
    hasSentence: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  data() {
    return {
      isLastStep: false,
      showHnaziIndex: false,
      dispearIdList: [],
      dispearImg: 0,
      beforeDispearIdList: [],
      dragId: null,
      dragIndex: null,
      beforeDragIndex: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dropImgSocketInfo: {},
      dragImgSocketInfo: {},
      clickMenuSocketInfo: {},

      currentIndex: 0,
      isShowSentence: false,
      buttonList: [
        {
          image: require("@/assets/img/01-Menu/menu-1.svg"),
          startNumberRange: 0,
          endNumberRange: 0,
        },
        {
          image: require("@/assets/img/01-Menu/menu-2.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/01-Menu/menu-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/01-Menu/menu-4.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  watch: {
    dropImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005701,
          data: { value },
          text: "DragToGressGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005702,
          data: { value },
          text: "DragToGressGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005704,
          data: { value },
          text: "dressDragGroupGame menu click socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropImgEvent", ({ item }) => {
      this.handleDropImg("socket", item, true);
    });
    this.$bus.$on("dragImgEvent", ({ item }) => {
      this.handleDragImg("socket", item, true);
    });
    this.$bus.$on("dressDargGroupGameClickMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropImgEvent");
    this.$bus.$off("dragImgEvent");
    this.$bus.$off("dressDargGroupGameClickMenu");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index);
      this.currentIndex = index;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.initGame();
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleDragImg(event, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      this.dragId = item.id;
      this.dragIndex = item.index;
    },
    handleDropImg(event, item, isFromSocket = false) {
      console.log("拖拽成功");
      if (!isFromSocket) {
        this.dropImgSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      if (item.id === this.dragId) {
        this.dispearIdList.push(item.id);
        playCorrectSound(true, false);

        if (
          this.dispearIdList.length === this.bgImgList[this.currentIndex].length
        ) {
          this.isShowSentence = true;
          setTimeout(() => {
            // this.currentIndex++;
            if (this.currentIndex === this.bgImgList.length - 1) {
              this.isLastStep = true;
              this.currentIndex = this.bgImgList.length - 1;
              startConfetti();
              playCorrectSound();
            }
          }, 1500);
        }
      } else {
        playCorrectSound(false);
      }
    },
    initGame() {
      this.dispearIdList = [];
      this.isShowSentence = false;
    },
    Sdrop: function (event) {
      event.preventDefault();
    },
    allowDrop: function (event) {
      event.preventDefault();
    },
    dragend() {
      this.dragIndex = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.option-List {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.title-area {
  z-index: 11;
  position: absolute;
  left: 0;
  top: 8%;
  width: 30%;
  height: 16%;
  background: #224e96;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #fff;
  .title {
    .title-pinyin,
    .title-hanzi {
      text-align: center;
    }
  }
}
.hanzi-item {
  width: 40%;
  margin: 0 auto;
  background: #224e96;
  position: absolute;
  bottom: 6%;
  left: 30%;
  padding: 0.5% 0%;
  color: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.darg-to-dress-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      position: relative;
      //   width: fit-content;
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
      .jacketIndex {
        z-index: 2;
      }
    }
    .drop-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      div {
        // background: #000;
        position: absolute;
        cursor: pointer;
        z-index: 6;
      }
    }
    .drag-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        cursor: pointer;
        width: 90%;
        -webkit-user-drag: inherit;
      }
      .notShow {
        opacity: 0;
      }
      .dispear {
        opacity: 0;
      }
      .option-item {
        position: absolute;
        -webkit-user-drag: auto;
        z-index: 8;
      }
    }
  }
  .lesson-27 {
    .drag-area {
      .option-1 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 37.3%;
        left: 6%;
      }
      .option-2 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 50%;
        left: 9%;
      }
      .option-3 {
        // background:#000;
        width: 20%;
        height: 6%;
        top: 27%;
        left:57.5%;
      }
      .option-4 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 27%;
        left: 74%;
      }
      .option-5 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 61%;
        left: 79%;
      }
      .option-6 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 37.3%;
        left: 6%;
      }
      .option-7 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 50%;
        left: 9%;
      }
      .option-8 {
        // background:#000;
        width: 20%;
        height: 6%;
        top: 27%;
        left: 57.5%;
      }
      .option-9 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 27%;
        left: 74%;
      }
      .option-10 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 61%;
        left: 79%;
      }

      .option-11 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 37%;
        left: 2%;
      }
      .option-12 {
        // background:#000;
        width: 14%;
        height: 6%;
        top: 39%;
        left: 19%;
      }
      .option-13 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 16%;
        left: 60%;
      }
      .option-14 {
        // background:#000;
        width: 8%;
        height: 7%;
        top: 12%;
        left: 80%;
      }
      .option-15 {
        // background:#000;
        width: 24%;
        height: 6%;
        top: 27%;
        left: 64.5%;
      }
      .option-16 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 62%;
        left: 70%;
      }
      .option-17 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 37%;
        left: 2%;
      }
      .option-18 {
        // background:#000;
        width: 14%;
        height: 6%;
        top: 39%;
        left: 19%;
      }
      .option-19 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 16%;
        left: 60%;
      }
      .option-20 {
        // background:#000;
        width: 8%;
        height: 7%;
        top: 12%;
        left: 80%;
      }
      .option-21 {
        // background:#000;
        width: 24%;
        height: 6%;
        top: 27%;
        left: 64.5%;
      }
      .option-22 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 62%;
        left: 70%;
      }
    }
  }
  .lesson-27 {
    .dropBox-1 {
      div {
        // background: #000;
        width: 10%;
        height: 22%;
        top: 39%;
        left: 45%;
      }
    }
    .dropBox-2 {
      div {
        // background: #000;
        width: 18%;
        height: 6%;
        top: 68%;
        left: 41%;
      }
    }
    .dropBox-3 {
      div {
        // background: #000;
        width: 10%;
        height: 12%;
        top: 32%;
        left: 45%;
      }
    }
    .dropBox-4 {
      div {
        // background: #000;
        width: 13%;
        height: 18%;
        top: 46%;
        left: 44%;
      }
    }
    .dropBox-5 {
      div {
        // background: #000;
        width: 22%;
        height: 7%;
        top: 68%;
        left: 40%;
      }
    }
    .dropBox-6 {
      div {
        // background: #000;
        width: 15%;
        height: 20%;
        top: 18%;
        left: 41%;
      }
    }
    .dropBox-7 {
      div {
        // background: #000;
        width: 13%;
        height: 28%;
        top: 41%;
        left: 42%;
        z-index: 9;
      }
    }
    .dropBox-8 {
      div {
        // background: #000;
        width: 20%;
        height: 7%;
        top: 77%;
        left: 39%;
      }
    }
    .dropBox-9 {
      div {
        // background: #000;
        width: 15%;
        height: 20%;
        top: 18%;
        left: 41%;
      }
    }
    .dropBox-10 {
      div {
        // background: #000;
        width: 13%;
        height: 28%;
        top: 41%;
        left: 42%;
      }
    }
    .dropBox-11 {
      div {
        // background: #000;
        width: 20%;
        height: 7%;
        top: 80%;
        left: 39%;
      }
    }
  }

  .lesson-35 {
    .drag-area {
      .option-1 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 37.3%;
        left: 6%;
      }
      .option-2 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 50%;
        left: 9%;
      }
      .option-3 {
        // background:#000;
        width: 20%;
        height: 6%;
        top: 27%;
        left: 64%;
      }
      .option-4 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 27%;
        left: 81%;
      }
      .option-5 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 61%;
        left: 79%;
      }
      .option-6 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 37.3%;
        left: 6%;
      }
      .option-7 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 50%;
        left: 9%;
      }
      .option-8 {
        // background:#000;
        width: 20%;
        height: 6%;
        top: 27%;
        left: 64%;
      }
      .option-9 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 27%;
        left: 81%;
      }
      .option-10 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 61%;
        left: 79%;
      }

      .option-11 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 37%;
        left: 2%;
      }
      .option-12 {
        // background:#000;
        width: 14%;
        height: 6%;
        top: 39%;
        left: 19%;
      }
      .option-13 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 16%;
        left: 62%;
      }
      .option-14 {
        // background:#000;
        width: 8%;
        height: 7%;
        top: 12%;
        left: 87%;
      }
      .option-15 {
        // background:#000;
        width: 24%;
        height: 6%;
        top: 27%;
        left: 72%;
      }
      .option-16 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 62%;
        left: 78%;
      }
      .option-17 {
        // background:#000;
        width: 18%;
        height: 6%;
        top: 37%;
        left: 2%;
      }
      .option-18 {
        // background:#000;
        width: 14%;
        height: 6%;
        top: 39%;
        left: 19%;
      }
      .option-19 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 16%;
        left: 62%;
      }
      .option-20 {
        // background:#000;
        width: 8%;
        height: 7%;
        top: 12%;
        left: 87%;
      }
      .option-21 {
        // background:#000;
        width: 24%;
        height: 6%;
        top: 27%;
        left: 72%;
      }
      .option-22 {
        // background:#000;
        width: 21%;
        height: 6%;
        top: 62%;
        left: 78%;
      }
    }
  }
  .lesson-35 {
    .dropBox-1 {
      div {
        // background: #000;
        width: 10%;
        height: 22%;
        top: 39%;
        left: 45%;
      }
    }
    .dropBox-2 {
      div {
        // background: #000;
        width: 18%;
        height: 6%;
        top: 68%;
        left: 41%;
      }
    }
    .dropBox-3 {
      div {
        // background: #000;
        width: 10%;
        height: 12%;
        top: 32%;
        left: 45%;
      }
    }
    .dropBox-4 {
      div {
        // background: #000;
        width: 13%;
        height: 18%;
        top: 46%;
        left: 44%;
      }
    }
    .dropBox-5 {
      div {
        // background: #000;
        width: 22%;
        height: 7%;
        top: 68%;
        left: 40%;
      }
    }
    .dropBox-6 {
      div {
        // background: #000;
        width: 15%;
        height: 20%;
        top: 18%;
        left: 41%;
      }
    }
    .dropBox-7 {
      div {
        // background: #000;
        width: 13%;
        height: 28%;
        top: 41%;
        left: 42%;
        z-index: 9;
      }
    }
    .dropBox-8 {
      div {
        // background: #000;
        width: 20%;
        height: 7%;
        top: 77%;
        left: 39%;
      }
    }
    .dropBox-9 {
      div {
        // background: #000;
        width: 15%;
        height: 20%;
        top: 18%;
        left: 41%;
      }
    }
    .dropBox-10 {
      div {
        // background: #000;
        width: 13%;
        height: 28%;
        top: 41%;
        left: 42%;
      }
    }
    .dropBox-11 {
      div {
        // background: #000;
        width: 20%;
        height: 7%;
        top: 80%;
        left: 39%;
      }
    }
  }
}
</style>
